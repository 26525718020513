import React from "react";
import Container from "./container";
import format from "date-fns/format";
import { imageUrlFor } from "../lib/image-url";
import { cn, buildImageObj } from "../lib/helpers";
import { Link } from "gatsby";
import Icon from "./icon";

import * as styles from "../styles/components/post-page-header.module.css";

const PageHeader = (props) => {

  const {
    title,
    landingTitle,
    author,
    date,
    image
  } = props;

  return (
    <div className={styles.root}>
      <div className={cn("grid", styles.grid)}>
        <div className={cn("col-12 ", (image && image.asset) ? "col-md-6" : styles.noImg, styles.col, styles.text)}>
          <Container>
            <div className={styles.contentWrap}>
              <Link className={styles.back} to="/news-updates"><Icon symbol="arrow-left" />{landingTitle}</Link>
              {title && (<h1 className={cn("h4", styles.title)}>{title}</h1>)}
              {(author || date) && (
                <div className={styles.meta}>
                  {author && (
                    <span className={styles.author}>By {author.name}</span>
                  )}
                  {date && (
                    <span className={styles.date}>{format(new Date(date).setDate(new Date(date).getDate() + 1), "MM.dd.yy")}</span>
                  )}
                </div>
              )}
            </div>
          </Container>
        </div>
        {image && image.asset && (
          <div className={cn("col-12 col-md-6", styles.col, styles.image)}>
            <img
              src={imageUrlFor(buildImageObj(image))
                .auto("format")
                .url()}
              alt={image.alt}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default PageHeader;
