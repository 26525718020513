import React from "react";
import { graphql } from "gatsby";
import Layout from "../containers/layout";
import GraphQLErrorList from "../components/graphql-error-list";
import Seo from "../components/seo";
import { Helmet } from 'react-helmet';

import PageHeader from "../components/news-page-header";
import PostMain from "../components/post-main";
import RelatedNews from "../components/news-related";

export const query = graphql`
  fragment SanityImage on SanityMainImage {
    crop {
      _key
      _type
      top
      bottom
      left
      right
    }
    hotspot {
      _key
      _type
      x
      y
      height
      width
    }
    asset {
      _id
    }
  }

  query NewsTemplateQuery($id: String!) {
    newsLanding: sanityNewsLanding {
      title
      themePurple
    }

    news: sanityNews(id: { eq: $id }) {
      id
      title
      publicationDate
      author {
        name
      }
      thumbnail {
        ...SanityImage
        alt
      }
      flexibleContent {
        ... on SanityFc2ColumnImage {
          _key
          _type
          imageLeft {
            ...SanityImage
            alt
            caption
          }
          imageRight {
            ...SanityImage
            alt
            caption
          }
        }
        ... on SanityFcContentBlock {
          _key
          _type
          _rawContent
        }
        ... on SanityFcImage {
          _key
          _type
          fullwidth
          image {
            ...SanityImage
            alt
            caption
          }
        }
        ... on SanityFcVideo {
          _key
          _type
          caption
          url
        }
      }
      relatedNews {
        _key
        news {
          title
          slug {
            current
          }
          author {
            name
          }
          publicationDate
          thumbnail {
            ...SanityImage
            alt
          }
        }
      }
      seo {
        title
        description
        thumbnail {
          asset {
            _id
          }
          alt
        }
      }
    }
  }
`;

const NewsTemplate = (props) => {
  const { data, errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }
  
  const newsLanding = (data || {}).newsLanding;
  const news = (data || {}).news;

  const title = news.seo?.title || news.title;
  const description = news.seo?.description;
  const image = news.seo?.thumbnail || news.thumbnail;
  const autoSiteTitle = !news.seo?.title;

  return (
    <Layout>
      <Seo
        title={title}
        description={description}
        image={image}
        autoSiteTitle={autoSiteTitle}
      />

      <Helmet>
        <html className={newsLanding.themePurple ? "theme-purple" : undefined} lang="en" />
      </Helmet>

      {news.title && (<PageHeader title={news.title} landingTitle={newsLanding.title} author={news.author} date={news.publicationDate} image={news.thumbnail} />)}
      {news.flexibleContent && (<PostMain content={news.flexibleContent} title={news.title} />)}
      {news.relatedNews && news.relatedNews.length > 0 && (<RelatedNews news={news.relatedNews} />)}
    </Layout>
  );
};

export default NewsTemplate;
