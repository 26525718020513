import React from "react";
import Container from "./container";
import NewsItem from "./news-item";
import { cn } from "../lib/helpers";

import * as styles from "../styles/components/news-section.module.css";

const RelatedNews = (props) => {

  const {
    news
  } = props;

  return (
    <div className={styles.root}>
      <Container>
        <h2 className={styles.title}>Related Articles</h2>
        <div className="grid">
          {news.map(({ news, _key }) => {
            return (
              <div key={_key} className={cn("col-12 col-md-6 col-xl-4", styles.item)}>
                <NewsItem news={news} />
              </div>
            );
          })}
        </div>
      </Container>
    </div>
  );
}

export default RelatedNews;
